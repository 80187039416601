<!--
 * @Description: 售后列表
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-08-10 14:53:54
 * @LastEditors: Seven
 * @LastEditTime: 2022-10-13 14:45:00
-->
<template>
  <div class="customer">
    <div ref="contentTop">
      <div class="tool">
        <div class="customer-tool">
            <div>
              <span class="tab-bar clearfix">
                <span
                  @click="handleClick(item.path)"
                  v-for="(item, index) in tabList"
                  :class="{ active: item.path === activeName }"
                  :key="index"
                  >{{ item.name }}</span
                >
              </span>
            </div>
            <div style="line-height: 40px">
              <el-button v-if="permUtil.WbOrder.orderAfterTurnClassPassedCancel()" :disabled="currCol.afterSaleType != 2" type="danger" class="hq-button" @click="visibleShow = true">转班撤销</el-button>
              <el-button v-if="permUtil.WbOrder.orderAfterRefundCancel()" :disabled="!(currCol.aftersaleTypeName == '退费' && currCol.status == 4)" type="danger" class="hq-button" @click="visibleShow = true">退费撤销</el-button>
              <Export v-if="permUtil.WbOrder.orderAfterExport()"  :pageParam="pageParam" :total="total" :searchParam="searchParam" />  
            </div>
        </div>
      </div>
      <div
        :gutter="10"
        style="width: 94%; margin: 0 auto"
      >
        <Search
          v-if="searchParamList.length"
          :searchAllParam="searchAllParam"
          @search="search"
          @reset="reset"
          @searchMore="searchMore"
          @searchList="searchList"
          :searchParamList="searchParamList"
          :searchParamFromApi="searchParamFromApi"
          code="afterSaleList"
        />
      </div>
      <div class="line"></div>
    </div>
    <el-row style="width: 94%; margin: 0 auto">
      <el-col>
        <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          :height="tableHeight"
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '20px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
          @row-click="clickRow"
        >
          <el-table-column :show-overflow-tooltip="true"
            label="学员姓名"
            prop="nickName"
            width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="原报名单号"
            prop="orderNo"
            width="150"
            
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="原报名商品"
            prop="goodsAddSpecName"
            width="200"
            
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="售后单号"
            prop="afterSaleNo"
            width="160"
            
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="售后报名商品"
            prop="goodsAddSpecOneAddTwo"
            width="200"
            
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="售后类型"
            prop="aftersaleTypeName"
            width="90"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="售后校区"
            prop="schoolName"
            width="140"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="售后状态"
            prop="statusNmae"
            width="100"
            
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="钉钉单号"
            prop="processInstanceCode"
            width="200"
          >
             <!-- <template slot-scope="scope">
                <span v-if="scope.row.processInstanceCode">{{scope.row.processInstanceCode}}</span>
                <span style="color:red;" v-else>发起审批失败</span>
             </template> -->
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="申请人"
            prop="creater"
            width="120"
            
          > 
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="申请时间"
            width="150"
            
            prop="createTime"
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="完成时间"
            prop="finishTime"
            width="150"
            
          ></el-table-column>
          <el-table-column
            label="操作"
            width="220"
            prop=""
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button v-if="scope.row.afterSaleType != 3" type="text"  @click="goDetail(scope.row)">详情</el-button>  
              <el-button v-if="scope.row.status == 0 && scope.row.afterSaleType != 3" type="text"  @click="deleteOrder(scope.row)">删除</el-button>
              <el-button v-if="scope.row.status !== 0 && scope.row.aftersaleTypeName != '升班'"  type="text" @click="showReviewHistory(scope.row)">审核记录</el-button>
              <el-button v-if="scope.row.afterSaleType != 3?scope.row.afterSaleType == 1?scope.row.status != 0 && scope.row.status != 6:scope.row.status === 1:false" type="text" @click="repeal(scope.row)">撤销</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParam.pageSize"
            :total="total"
          />
        </div>
      </el-col>
    </el-row>
    <review-history 
      :visible="onReviewHistoryStatus"
      :reviewData="reviewData"
      ref="drawer"
      @on-cancel="onReviewHistoryCancel"
    />
    <!-- 退费撤销 -->
    <HqModal :visible="visibleShow" :title="currCol.afterSaleType == 2?'转班':'退费撤销'" width="400" @on-cancel="visibleShow = false">
      <el-form ref="form" label-width="100px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="售后单号">
              <span>{{ currCol.afterSaleNo }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="原订单">
              <span>{{ currCol.orderNo }}</span>
            </el-form-item>
          </el-col>
          <br />
          <!-- <el-col :span="24" v-if="currCol.afterSaleType == 2">
            <el-form-item prop="recruitChannelsId" label="转班订单">
              <span>{{ currCol.isLoan }}</span>
            </el-form-item>
          </el-col> -->
          <template v-if="currCol.afterSaleType != 2">
            <el-col :span="24">
              <el-form-item prop="recruitChannelsId" label="是否贷款退费">
                <span>{{ currCol.isLoan }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="recruitChannelsId" label="付款方式">
                <span>{{ currCol.payTypeName }}</span>
              </el-form-item>
            </el-col>
          </template>
          
        </el-row>
      </el-form>
      <template v-if="currCol.afterSaleType == 2">
        <div style="fontSize: 12px;fontWeight: bold;margin-bottom: 12px">
          使用场景：校区在转班审批通过后，发现转班申请有问题，或学员后悔，或招生老师填写转班金额有误，或订单金额有误解等，需重新申请！
        </div>
        <div style="fontSize: 12px;color: red">
          操作指引：
          <br/>
          1、检查NC 智慧校区售后单的付款单的勾对状态，如果已勾对，先看付款方式，如果是银行、贷款类付款，则要检查资金部是否有打款，如果有打款则不能撤销，没有打款可以撤销；如果是余额，则可以撤销
          <br/>
          2、检查转班的订单是否已经收款，如果已经存在收款，则不允许撤销
        </div>
      </template>
      <template v-else>
        <div style="fontSize: 12px;fontWeight: bold;margin-bottom: 12px">
          使用场景：校区在退费/转班审批通过后，发现退费/转班申请有问题，或学员后悔，或招生老师填写退费金额有误，或退款审批通过，资金部没打款，需要撤回退费、重新申请！
        </div>
        <div style="fontSize: 12px;color: red">
          操作指引：检查NC 智慧校区付款单勾对状态，如果已勾对，先看付款方式，如果是银行、贷款类付款，则要检查资金部是否有打款，如果有打款则不能撤销，没有打款可以撤销；如果是余额，且余额还没有被使用，则可以撤销
        </div>
      </template>
      <div slot="footer">
        <el-button @click="visibleShow = false">取消</el-button>
        <el-button @click="saveClick" type="primary">确认</el-button>
      </div>
    </HqModal>
  </div>
</template>
<script>
import Search from "@/components/search";
import { 
  reqworkbenchDeptTree,
  getUserCustomQuery
} from "api/public";
import { getOrderAfterSalesList, doRefundCancel, doTurnClassPassedCancel } from "api/order/change";
import { OrderOriginTypeEnum } from '../constants';

export default {
  name: "orderList",
  components: {
    Search,
    reviewHistory : () => import("../components/reviewHistory"),
    Export: () => import("./export"),
  },
  data() {
    return {
      activeName: "-1",
      tabList: [
        { name: "所有", path: "-1" },
        { name: "退费", path: "0" },
        { name: "升班", path: "1" },
        { name: "转班", path: "2" },
        { name: "转校", path: "3" },
      ],
      tableData: [],
      listLoading: false,
      searchAllParam: [],
      searchAllParamList: [
        { name: "nickName", label: "学员姓名", type: "input", value: ""},
        { name: "orderNo", label: "原报名单号", type: "input", value: "" },
        { name: "aftersaleNo", label: "售后单号", type: "input", value: "" },
        { name: "customerId", label: "客户编号", type: "input", value: "" },
        {
          name: "statusList",
          label: "售后状态",
          type: "allSelect",
          option: [
            // {
            //   id: 0,
            //   name: '待提交'
            // }, 
            {
              id: 1,
              name: '审核中'
            }, 
            {
              id: 6,
              name: "已撤回",
            },
            {
              id: 3,
              name: '审核驳回'
            },
            {
              id: 2,
              name: '处理中'
            },
            {
              id: 5,
              name: '处理异常'
            }, 
            {
              id: 4,
              name: '已完成'
            },
          ],
          value: "",
          text: "name", //label
          id: "id"   //value
        },
        {
          name: "deptIdList",
          label: "售后校区",
          type: "multiTree",
          option: [],
          value: [],
        },
        { 
          name: "createDate", 
          label: "申请时间", 
          type: "date", 
          value: [], 
          ref: "create", 
          show: false 
        },
        { name: "processInstanceCode", label: "钉钉单号", type: "input", value: "" },
        { name: "phoneNumber", label: "客户手机", type: "input", value: "" },
        {
          name: "refundStatus",
          label: "退费状态",
          type: "select",
          option: [
            {
              id: -1,
              name: '全部'
            },
            {
              id: 0,
              name: '待处理'
            },
            {
              id: 1,
              name: '已处理'
            },
          ],
          value: "",
          text: "name", //label
          id: "id"   //value
        },
        {
          name: "approveDingTalkName",
          label: "审核人",
          type: "input",
          value: ""
        },
      ],
      searchParamList: [],
      defaultParamList: ["nickName", "aftersaleType"],
      searchParamFromApi:[],
      total: 0,
      pageParam: {
        pageNum: 1,
        pageSize: 20,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      },
      searchParam: {},
      onReviewHistoryStatus: false,
      reviewData: {},
      // 页面是否有滚动条
      isScroll: true,
      ddId: "",
      topHeight: 0,
      value: false,
      currCol: {},
      visibleShow: false,
    };  
  },
  computed: {
    tableHeight() {
      return window.outerHeight - this.topHeight - 250 + 'px'
    }
  },
  mounted() {
    this.activeName = this.$route.query.type || "-1"
    this._getSearchAll()
    this.getSearchList();
    this.getcustomerList();
    this.$fetch('getTyUserInfoById', {
      id: this.$store.state.user.loginUser.tyUserId
    }).then( res=>{
      this.ddId = res.data.dingtalkUserId
    })
  },
  methods: {
    // tab切换
    handleClick(index) {
      this.activeName = index;
      this.$router.push({ query: { type: this.activeName } });
      this.searchAllParam = this.activeName == 1? this.searchAllParamList.filter(item => item.name != 'refundStatus' && item.name != 'approveDingTalkName'):this.searchAllParamList;
      this.searchMore(this.value)
      this.$forceUpdate();
      this.getcustomerList()
    },
    // 获取一开始的下拉框来源
    _getSearchAll() {
      this.searchAllParamList.map((item) => {
        if(item.name == "deptIdList") {
          // 获取成交部门树形结构
          reqworkbenchDeptTree().then((res) => {
              item.option = res.result
          })
        }
      })
      this.searchAllParam = this.activeName == 1? this.searchAllParamList.filter(item => item.name != 'refundStatus' && item.name != 'approveDingTalkName'):this.searchAllParamList;
    },
    // 更新自定义查询
    searchList() {
      this.getSearchList()
    },
    // 更多查询条件
    searchMore(value) {
      this.value = value
      this.searchParamList = value
        ? this.searchAllParam
        : this.activeName == 1?this.defaultParamList.filter(item => item.name != 'refundStatus' && item.name != 'approveDingTalkName'):this.defaultParamList;
      this.$nextTick(() => {
        this.topHeight = this.$refs.contentTop.offsetHeight + 8
        this.$forceUpdate();
      })
    },
    // 获取自定义查询的默认查询字段
    async getSearchList() {

      const {code, result } = await getUserCustomQuery('afterSaleList');
      if(code === 0) {
        this.defaultParamList = ["nickName", "status", "aftersaleType"]
        if (result && result.fieldData && JSON.parse(result.fieldData).length > 0) {
          
          this.searchParamFromApi = JSON.parse(result.fieldData)
          
          this.defaultParamList = []
          this.searchParamFromApi.map(() => {
            this.defaultParamList.push("")
          })
          let index
          this.searchAllParam.filter((item) => {
            index = this.searchParamFromApi.findIndex(i => i === item.name)
            this.defaultParamList[index] = item
          });
        } else {
          this.defaultParamList = this.searchAllParam.filter((item) =>
            this.defaultParamList.includes(item.name)
          );
        }
        this.searchParamList = this.defaultParamList;
        this.$nextTick(() => {
          this.topHeight = this.$refs.contentTop.offsetHeight + 8
        })
      }
    },
    // 获取列表数据
    async getcustomerList() {
      this.listLoading = true;
      if(this.activeName != -1) {
        this.searchParam.aftersaleType = this.activeName;
      } else {
        delete this.searchParam.aftersaleType
      }
      const { code, result, msg } = await getOrderAfterSalesList({...this.pageParam, ...this.searchParam})
      if( code == 0 ){
        this.tableData = result.list;
        this.total = result.total;
        this.currCol = {}
        this.$nextTick(() => {
          this.isScroll = document.body.scrollHeight > window.innerHeight
        })
      }else{
        this.$message.error(msg)
      }
   
      this.listLoading = false;
    },
    // 单选事件
    clickRow(row) {
      this.currCol = row;
    },
    // 改变分页条数
    sizeChange(val) {
      this.pageParam.pageSize = val;
      this.getcustomerList()
    },

    // 改变分页
    currentChange(val) {
      this.pageParam.pageNum = val;
      this.getcustomerList()
    },
    // 去售后单详情页
    goDetail(row) {
      this.$router.push(
        `/afterSale/${row.orderOriginType === OrderOriginTypeEnum.nc ? 'ncAfterSaleDetail' : 'afterSaleDetail'}?id=${row.id}&type=${row.afterSaleType}`
      );
    },
    async deleteOrder(row) {

      this.$hqMessageBox({
        title: '提示',
        message: '删除订单?'
      }).then(async () => {
          const params = {
            idStr: row.id,
          };
          const { code, msg } = await this.$fetch("aftersaleDelete", params);
          if (code === 200) {
            this.$message.success(msg);
            this.getcustomerList();
          } else {
            this.$message.error(msg);
          }
        })
        .catch(() => {
          // reject(false);
        });
    },
    //展示审核记录
    showReviewHistory(row) {
      this.reviewData = row
      // this.onReviewHistoryStatus = true
      this.$refs.drawer.init(row)
    },
    //撤销
    async repeal(row) {
      this.refuseReason = "";
      this.currentRow = row;
      this.$hqMessageBox({
        title: '提示',
        message: '此操作将撤销该售后?'
      }).then(() => {
          this.sureReject();
        })
        .catch((e) => {
          // reject(false);
        });
    },
    async sureReject() {
      if(!this.ddId) {
        this.$message.error("该用户没有钉钉id,不能进行撤销操作！")
        return false
      }
      const params = {
        id: this.currentRow.id,
        userId: this.ddId,
        remark: this.refuseReason,
      };
      try {
        await this.$fetch('aftersaleRepeal', params).then(res=>{
          console.log('res', res);
          if(res.code !== 200) {
            this.$message.error(res.msg)
            return
          }
          this.getcustomerList()
          let index = this.tableData.findIndex((item) => item == this.currentRow);
          this.tableData[index].status = 0; //自动设置为草稿状态
          this.showRefuse = false;
          this.$message.success("撤销成功");
        }).catch((e) => {
          console.log('e', e);
          this.$message.error(e.msg)
        })
      } catch (e) {
        console.log(e, '--------------');
      }
      
  
      // const { code, msg } = await this.$fetch("aftersaleRepeal", params);
      // console.log('code-----', code);
      // if( code != 200 ){
      //   console.log('msg', msg);
      //   this.$message.error(msg)
      //   return;
      // }
      // this.getcustomerList()
      // let index = this.tableData.findIndex((item) => item == this.currentRow);
      // this.tableData[index].status = 0; //自动设置为草稿状态
      // this.showRefuse = false;
      // this.$message.success("撤销成功");
    },
    //审核记录返回按钮
    onReviewHistoryCancel() {
      this.onReviewHistoryStatus = false;
    },
    // 撤销退费
    saveClick() {
      if(this.currCol.afterSaleType == 2) {
        // 转班撤销
        doTurnClassPassedCancel({afterSaleId: this.currCol.id}).then(res => {
          if(res.code == 0) {
            this.$message.success(res.msg)
            this.getcustomerList()
            this.currCol = {}
          } else {
            this.$message.error(res.msg)
          }
          this.visibleShow = false
        })
      } else {
        const params = {
          afterSaleNo: this.currCol.afterSaleNo,
          afterSaleType: this.currCol.afterSaleType,
          isLoanRefund: this.currCol.isLoanRefund,
          orderNo: this.currCol.orderNo,
          payType: this.currCol.payType,
          status: this.currCol.status
        }
        // 退费撤销
        doRefundCancel(params).then(res => {
          if(res.code == 0) {
            this.$message.success(res.msg)
            this.getcustomerList()
            this.currCol = {}
          } else {
            this.$message.error(res.msg)
          }
          this.visibleShow = false
        })
      }
    },
    // 查询条件
    search() {
      this.searchParam = {}
      this.searchAllParam.forEach( item=>{
          if( item.value || item.value === 0 || ( Array.isArray(item.value)  && item.value.length ) ) {
          if(item.type === "date") {
            if(item.name === "createDate") {
              this.searchParam.createDateStart = item.value[0];
              this.searchParam.createDateEnd = item.value[1];
            }
          } else {
            this.searchParam[item.name] = item.value 
          }
        }
      })
      this.getcustomerList()
    },
    // 重置
    reset() {
      this.searchParamList.map(item => {
        if(item.value || item.value == 0) {
          if(item.name === "createDate") {
            item.value = []
          } else {
            item.value = ""
          }
        }
      })

      this.pageParam = {
        pageNum: 1,
        pageSize: 20,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      },
      
      this.search()
    },
    /*********************** 页面跳转部分  *******************************/
    // 跳转到详情页面
    toDetaiPage( dataItem ){  
      let path = `${dataItem.businessId}` === '1' ?  "/afterSale/cjOrderDetail": "/afterSale/xlOrderDetail";
      this.$router.push( {
        path: path,
        query: {
          orderId: dataItem.id
        }
      });      
    },

    /**
     * 跳转到报名页面
     */
    toSignUpPage(){
      this.$router.push("/aftersale/signup")
    }
  },
};
</script>
<style lang="scss" scoped>
.customer {
  // padding-bottom: 40px;
  .titie {
    text-align: left;
  }

  .tool {
    background: #f5f7f9;
  }

  .customer-tool {
    display: flex;
    justify-content: space-between;
    width: 94%;
    margin: 0 auto;

    .tab-bar {
      overflow: hidden;

      .active {
        color: #409eff;
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 4px;
          background-color: #337aff;
          bottom: 0;
          left: 0;
        }
      }

      > span {
        float: left;
        margin-right: 12px;
        line-height: 40px;
        text-align: center;
        text-decoration: none;
        color: #000;
        cursor: pointer;
        font-size: 14px;
        padding: 0 10px;
      }
    }
  }
  .phone {
    .el-button--text {
      padding: 10px 4px;
    }
  }
}

.el-form-item {
  margin-bottom: 0px!important;
}
</style>
